import axios from 'axios';
import { toast } from 'react-toastify';
import { ENV } from '../config/config';
import { logout } from '../services/navigatorService';

let baseUrl = ENV.serverUrl;

async function apiHelper(
  apiType,
  path,
  data,
  params,
  responseType = 'application/json',
  headers = {},
) {
  if (baseUrl === undefined || !baseUrl) {
    baseUrl = '';
  }
  const xauthtoken = localStorage.getItem('token') ?? sessionStorage.getItem('token');

  if (
    apiType === 'post'
    || apiType === 'put'
    || apiType === 'get'
    || apiType === 'delete'
    || apiType === 'patch'
  ) {
    try {
      const response = await axios({
        method: apiType,
        url: `${baseUrl + path}`,
        data,
        params,
        responseType,
        headers: {
          ...headers,
          Authorization: xauthtoken ? JSON.parse(xauthtoken) : null,
        },
      });

      return response;
    } catch (error) {
      if (error.response.data.isTokenExpired) {
        if (localStorage.getItem('userId')) {
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');
          logout();
          toast.warning(error.response.data.message);
        }
      } else {
        toast.error(error.response.data.message);

        return error;
      }
    }
  }
}

export { apiHelper };
