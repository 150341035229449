/* eslint-disable default-param-last */
import {
  SET_IS_DATA_LOADING,
  SET_TOTAL_PAGES,
  SET_SMS_HISTORY,
  ADD_SMS_HISTORY,
  ADD_LAST_SMS_HISTORY,
} from './userSmsHistoryTypes';

const initialState = {
  data: [],
  isLoading: false,
  totalPages: 10,
};

const userSmsHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DATA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case SET_SMS_HISTORY:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_SMS_HISTORY:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload,
        ],
      };
    case ADD_LAST_SMS_HISTORY:
      return {
        ...state,
        data: [
          action.payload,
          ...state.data,
        ],
      };
    default:
      return {
        ...state,
      };
  }
};

export default userSmsHistoryReducer;
