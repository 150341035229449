import React, {
  useEffect, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getEmailTemplate, setEmailTemplateCreator } from '../../../redux/emailTemplates/emailTemplatesActions';
import styles from './SmsServiceEdit.module.css';
import { selectIntegratedServices, selectIsSmsServicesLoading, selectSmsServices } from '../../../redux/smsServices/smsServicesSelectors';
import { fetchSmsServiceById, updateSmsServiceById } from '../../../redux/smsServices/smsServicesActions';
import AuthDataComponent from '../AuthDataComponent/AuthDataComponent';
import AdditionalParamsComponent from '../AdditionalParamsComponent/AdditionalParamsComponent';

function SmsServiceEdit({ id }) {
  const dispatch = useDispatch();

  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const integratedServices = useSelector(selectIntegratedServices);

  const [description, setDescription] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [agent, setAgent] = useState(crmUsers[0]?._id);
  const [isActive, setIsActive] = useState(false);
  const [authData, setAuthData] = useState(null);
  const [additionalParams, setAdditionalParams] = useState(null);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (integratedServices && integratedServices.length > 0) {
      setServiceName(integratedServices[0]);
    }
  }, [integratedServices]);

  const { handleSubmit } = useForm();

  const smsServices = useSelector(selectSmsServices);
  const isDataLoading = useSelector(selectIsSmsServicesLoading);

  useEffect(() => {
    dispatch(fetchSmsServiceById(id));
  }, [id]);

  useEffect(() => {
    const service = smsServices.find((service) => service._id === id);

    setDescription(service.description);
    setServiceName(service.service_name);
    setAuthData(service.auth_data ?? {});
    setAdditionalParams(service.additional_params ?? {});
    setIsActive(service.status);
    setAgent(service.assigned_to);
  }, [smsServices]);

  const handleAuthDataChange = (newAuthData) => {
    setAuthData(newAuthData);
  };

  const handleAdditionalParamsChange = (newData) => {
    setAdditionalParams(newData);
  };

  const handleSave = async () => {
    setIsSending(true);

    const data = {
      description,
      service_name: serviceName,
      auth_data: authData,
      additional_params: additionalParams,
      is_active: isActive,
      assigned_to: agent,
    };
    dispatch(updateSmsServiceById(id, data, setIsSending));
  };

  if (isDataLoading) {
    return <>Loading</>;
  }

  return (
    <div className={styles.wrapper}>
      <form className="bitrex-form form-group" onSubmit={handleSubmit(handleSave)}>

        <div className="form-group">
          <label className="lead" htmlFor="label">Description</label>
          <input
            type="text"
            name="description"
            value={description}
            onInput={(e) => setDescription(e.target.value)}
            placeholder="Enter here..."
            className="form-control"
          />
        </div>
        <br />

        <div className="form-group">
          <label className="lead" htmlFor="label">Service Name</label>
          <select
            name="serviceName"
            value={serviceName}
            className={styles.selector}
            onChange={({ target: { value } }) => dispatch(setServiceName(value))}
          >
            {integratedServices.map((service) => <option key={service}>{service}</option>)}
          </select>
        </div>
        <br />

        <div className="form-group">
          <label className="lead">Status</label>
          <select
            className={styles.selector}
            name="isActive"
            value={isActive}
            onChange={() => setIsActive(!isActive)}
          >
            <option value>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
        <br />

        <div className="form-group">
          <label className="lead">Agent</label>
          <select
            className="form-control user-status-select"
            name="agent"
            onChange={({ target }) => { setAgent(target.value); }}
          >
            {crmUsers.map((user, key) => (
              <option key={user._id} selected={key === 0} value={user._id}>
                {`${user.firstName} ${user.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <br />
          
        {
          authData && (
            <AuthDataComponent
              onAuthDataChange={handleAuthDataChange}
              defaultAuthData={authData}
            />
          )
        }

        {
          additionalParams && (
            <AdditionalParamsComponent
              onAdditionalParamsChange={handleAdditionalParamsChange}
              defaultAdditionalParams={additionalParams}
            />
          )
        }

        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn-default"
            disabled={isSending}
          >
            Update Sms Service
          </button>
        </div>
      </form>
    </div>
  );
}

export default SmsServiceEdit;
