import React, { useEffect, useState } from 'react';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import downloadIcon from '../assets/download.png';
import { SortTableIcon } from '../assets/SortTableIcon';
import styles from './FunnelFTD.module.css';
import {
  selectAffiliatesDashboardFunnelNameData, selectAffiliatesDashboardFunnelNameFilterOptions, selectAffiliatesDashboardFunnelNameTotal, selectStatusOption, 
} from '../../../redux/affiliatesDashboard/affiliatesDashboardSelectors';
import { fetchFTDByFunnel, getFunnelOptions } from '../../../redux/affiliatesDashboard/affiliatesDashboardActions';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';
import { FunnelNameFilter } from '../components/FunnelNameFilter';

function FunnelFTD({ filterData, setFilterData }) {
  const dispatch = useDispatch();

  const affiliateDashboardFunnelTableKey = 'Affiliate_Dashboard/funnel_table'; 
  const affiliateDashboardFilterStorage = 'Affiliate_Dashboard/filters'; 
  const {
    page = 1, perPage = 5, sort = null, sortDirection = true, 
  } = JSON.parse(localStorage.getItem(affiliateDashboardFunnelTableKey) ?? '{}');

  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(perPage);
  const [sortColumn, setSortColumn] = useState(sort);
  const [sortColumnDirection, setSortColumnDirection] = useState(sortDirection);
  const [selectedAffiliates, setSelectedAffiliates] = useState([]);
  const [funnelNameItems, setFunnelNameItems] = useState(new Set());
  
  const data = useSelector(selectAffiliatesDashboardFunnelNameData);
  const total = useSelector(selectAffiliatesDashboardFunnelNameTotal);
  const funnelNameFilterOptions = useSelector(selectAffiliatesDashboardFunnelNameFilterOptions);
  const statusOption = useSelector(selectStatusOption);


  const updateLocalStorageData = () => {
    const data = {
      page: currentPage,
      perPage: rowsPerPage,
      sort: sortColumn,
      sortDirection: sortColumnDirection,
    };

    localStorage.setItem(affiliateDashboardFunnelTableKey, JSON.stringify(data));
  };

  const handleChangeFunnelName = (id) => {
    const newSelectedItems = new Set(funnelNameItems);

    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }

    setFunnelNameItems(newSelectedItems);
    setFilterData((prevState) => ({ ...prevState, funnelNames: [...newSelectedItems] }));
  };

  const setStoredFilterData = () => {
    const affiliateDashboardStorage = localStorage.getItem(affiliateDashboardFilterStorage);

    if (affiliateDashboardStorage) {
      const filters = JSON.parse(affiliateDashboardStorage);

      if (filters.funnelNames) {
        setFunnelNameItems(new Set(filters.funnelNames) || new Set([]));
      }
    }
  };

  useEffect(() => {
    dispatch(fetchFTDByFunnel(currentPage, rowsPerPage, sortColumn, sortColumnDirection, filterData));
    updateLocalStorageData();

    if (filterData?.affiliate) {
      setSelectedAffiliates(filterData.affiliate);
    }
    if (filterData?.funnelNames === undefined) {
      setFunnelNameItems(new Set());
    }
  }, [currentPage, rowsPerPage, sortColumn, sortColumnDirection, filterData]);

  useEffect(() => {
    setStoredFilterData();
  }, [funnelNameFilterOptions]);

  useEffect(async () => {
    if (selectedAffiliates) {
      await dispatch(getFunnelOptions(selectedAffiliates));
    }
  }, [selectedAffiliates]);

  const totalPages = Math.ceil(total / rowsPerPage);

  const handleRowsPerPageChange = ({ target }) => {
    setRowsPerPage(target.value);
  };

  const handleClickPrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleClickNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  if (data === null) {
    return <>Loading</>;
  }

  const fromRow = currentPage * rowsPerPage - (rowsPerPage - 1);
  const toRow = currentPage * rowsPerPage;

  const handleSortByColumn = (columnName) => () => {
    if (sortColumn === columnName) {
      setSortColumnDirection((direction) => !direction);
    } else {
      setSortColumn(columnName);
      setSortColumnDirection(true); // mean: ASC
    }
  };

  const isLeftPageBtnDisabled = currentPage === 1;
  const isRightPageBtnDisabled = currentPage === totalPages;

  const leftPageBtnStyles = {
    height: '15px',
    cursor: isLeftPageBtnDisabled ? 'not-allowed' : 'pointer',
    color: isLeftPageBtnDisabled ? '#66676F' : '#C4C6D0',
  };

  const rightPageBtnStyles = {
    height: '15px',
    cursor: isRightPageBtnDisabled ? 'not-allowed' : 'pointer',
    color: isRightPageBtnDisabled ? '#66676F' : '#C4C6D0',
  };

  const getSortStatusForColumnIcon = (columnName) => (sortColumn === columnName ? sortColumnDirection ? 'asc' : 'desc' : 'idle');

  return (
    <div className={styles.ftdContainer}>
      <div className={styles.ftdTitleWrapper}>
        <div className={styles.ftdTitle}>Funnel name</div>
        {/* <img className={styles.downloadIcon} src={downloadIcon} alt="#" height={16} width={16} /> */}
      </div>

      <div style={{ overflowX: 'auto' }}>
        <div className={styles.ftdHeader}>
          <div className={styles.ftdHeaderItem}>
            Funnel name
            <FunnelNameFilter
              data={funnelNameFilterOptions}
              selectedItems={funnelNameItems}
              setSelectedItems={setFunnelNameItems}
              handleChange={handleChangeFunnelName}
              setFilterObject={setFilterData}
            />
          </div>
          <div className={styles.ftdHeaderItem} onClick={handleSortByColumn('countUsers')}>
            Leads
            <SortTableIcon status={getSortStatusForColumnIcon('countUsers')} />
          </div>
          <div className={styles.ftdHeaderItem} onClick={handleSortByColumn('countFTD')}>
            FTD
            <SortTableIcon status={getSortStatusForColumnIcon('countFTD')} />
          </div>
          <div className={styles.ftdHeaderItem} onClick={handleSortByColumn('cr')}>
            CR
            <SortTableIcon status={getSortStatusForColumnIcon('cr')} />
          </div>
          <div className={styles.ftdHeaderItem} onClick={handleSortByColumn('netDeposit')}>
            Net deposits
            <SortTableIcon status={getSortStatusForColumnIcon('netDeposit')} />
          </div>
        </div>

        <div className={styles.ftdTable}>
          {data.length ? data.map((row) => {
            const colorClass = row.netDeposits > 0 ? styles.ftdNetDepositGreen : styles.ftdNetDepositRed;
            const netDepositClass = `${styles.ftdGridItem} ${colorClass}`;

            return (
              <div key={row.funnel} className={styles.ftdGridRow}>
                <div className={styles.ftdGridItem}>
                  <Tooltip title={row.funnel}>
                    {row.funnel}
                  </Tooltip>
                </div>
                <div className={styles.ftdGridItem}>
                  <Tooltip title={row.leads}>
                    {row.leads}
                  </Tooltip>
                </div>
                <div className={styles.ftdGridItem}>
                  <Tooltip title={row.ftd}>
                    {row.ftd}
                  </Tooltip>
                </div>
                <div className={styles.ftdGridItem}>
                  <Tooltip title={`${row.cr?.toFixed(2)}%`}>
                    {`${row.cr?.toFixed(2)}%`}
                  </Tooltip>
                </div>
                <div className={netDepositClass}>
                  <Tooltip title={`$ ${row.netDeposits}`}>
                    {`$ ${row.netDeposits}`}
                  </Tooltip>
                </div>
              </div>
            );
          }) : (
            <div className={styles.noDataWrapper}>
              <NoDataAvailableTableIcon />
              <p className={styles.noDataWrapperTitle}>No data available</p>
            </div>
          )}
        </div>
      </div>

      <div className={styles.pagination}>
        <div className={styles.rowsPerPageWrapper}>
          <span className={styles.rowsPerPageWrapper}>Rows per page:</span>
          <select className={styles.rowsPerPageSelector} onChange={handleRowsPerPageChange} defaultValue={rowsPerPage}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        </div>
        <div className={styles.pageInfo}>
          <span>{`${fromRow}-${toRow} of ${total}`}</span>
        </div>
        <div className={styles.pageButtonsWrapper}>
          <ArrowBackIosNewSharpIcon onClick={handleClickPrevious} sx={leftPageBtnStyles} />
          <ArrowForwardIosSharpIcon onClick={handleClickNext} sx={rightPageBtnStyles} />
        </div>
      </div>
    </div>
  );
}

export default FunnelFTD;
