import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export function DoughnutChart({ data, colors, isMobile = false }) {
  useEffect(() => {
    const root = am5.Root.new('chartdiv');

    // Set theme
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        innerRadius: am5.percent(40),
      }),
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        innerRadius: am5.percent(60),
      }),
    );

    const colorSet = am5.ColorSet.new(root, {
      colors: Object.values(colors).map((color) => am5.color(color)),
    });

    series.set('colors', colorSet);

    // Prepare data for the chart
    const formattedData = data.slice(1).map(([category, value]) => {
      const [salesStatus] = category.split(':');
      return {
        category,
        value: Number(value),
        color: colors && colors[salesStatus] ? am5.color(colors[salesStatus]) : am5.color('#fff'),
      };
    });

    series.slices.template.setAll({
      propertyFields: {
        fill: 'color',
      },
    });

    series.data.setAll(formattedData);

    // Configure labels to match slice colors
    series.labels.template.setAll({
      forceHidden: false,
      text: '[bold{color}]{category}[/]',
      oversizedBehavior: 'wrap',
      fill: am5.color('#fff'),
      fontSize: '12px',
    });

    // Add animations
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, colors, isMobile]);

  return <div id="chartdiv" style={{ width: '100%', height: '200px', marginBottom: '40px' }} />;
}
