import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInfiniteScroll from '../../../../hooks/useInfinityScroll';
import styles from './UserSmsHistory.module.css';
import { selectIsSmsHistoryLoading, selectSmsHistory, selectTotalPagesLoading } from '../../../../redux/userSmsHistory/userSmsHistorySelectors';
import { getUserSmsHistory, setSmsHistoryCreator } from '../../../../redux/userSmsHistory/userSmsHistoryActions';

export function UserSmsHistory({ userId }) {
  const dispatch = useDispatch();

  const history = useSelector(selectSmsHistory);
  const isLoading = useSelector(selectIsSmsHistoryLoading);
  const totalPages = useSelector(selectTotalPagesLoading);

  const dateOptions = {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };

  const { loadMoreRef, page } = useInfiniteScroll(totalPages);

  useEffect(() => {
    if (userId) {
      dispatch(getUserSmsHistory(userId, page));
    }
  }, [dispatch, userId, page]);

  // unmount
  useEffect(() => () => {
    dispatch(setSmsHistoryCreator([]));
  }, [userId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h5>Sms history</h5>
      </div>
      <div className={styles.bodyWrapper}>
        {
          history && history.length
            ? history.map((data) => {
              const date = new Date(data.createdAt);
              return (
                <span className={styles.statusHistoryRow}>
                  {date.toLocaleDateString('en-US', dateOptions)}
                  {' '}
                  Sms was sent to the client by
                  {' '}
                  {data.agentName}
                </span>
              );
            })
            : 'There hasn\'t been any activity yet'
        }
        { page < totalPages && <div ref={loadMoreRef}>{isLoading && 'Loading...'}</div> }
      </div>
    </div>
  );
}
