import { toast } from 'react-toastify';
import {
  SET_SMS_SERVICES,
  ADD_SMS_SERVICE,
  UPDATE_SMS_SERVICE,
  DELETE_SMS_SERVICE,
  SET_IS_SERVICES_LOADING,
  SET_DESCRIPTION_FILTER,
  SET_SERVICE_NAME_FILTER,
  SET_IS_ACTIVE_FILTER,
  SET_AGENT_FILTER,
  SET_INTEGRATED_SERVICES,
  SET_AUTH_DATA_TYPES,
  SET_TOTAL_COUNT,
  SET_CRM_USER_SERVICES,
  SET_CRM_USER_SELECTED_SERVICE,
} from './smsServicesTypes';
import { apiHelper } from '../apiHelper';
import { createUserSmsHistory } from '../userSmsHistory/userSmsHistoryActions';

export const setCrmUserSelectedSmsService = (service) => ({
  type: SET_CRM_USER_SELECTED_SERVICE,
  payload: service,
});

export const setCrmUserSmsServices = (services) => ({
  type: SET_CRM_USER_SERVICES,
  payload: services,
});

export const setSmsTotalCount = (totalCount) => ({
  type: SET_TOTAL_COUNT,
  payload: totalCount,
});

export const setIsSmsServicesLoading = (isLoading) => ({
  type: SET_IS_SERVICES_LOADING,
  payload: isLoading,
});

export const setDescriptionFilter = (filterData) => ({
  type: SET_DESCRIPTION_FILTER,
  payload: filterData,
});

export const setServiceNameFilter = (filterData) => ({
  type: SET_SERVICE_NAME_FILTER,
  payload: filterData,
});

export const setIsActiveFilter = (filterData) => ({
  type: SET_IS_ACTIVE_FILTER,
  payload: filterData,
});

export const setAgentFilter = (filterData) => ({
  type: SET_AGENT_FILTER,
  payload: filterData,
});


export const setIntegratedServices = (services) => ({
  type: SET_INTEGRATED_SERVICES,
  payload: services,
});

export const setAuthDataTypes = (services) => ({
  type: SET_AUTH_DATA_TYPES,
  payload: services,
});


export const setSmsServices = (services) => ({
  type: SET_SMS_SERVICES,
  payload: services,
});

export const addSmsService = (service) => ({
  type: ADD_SMS_SERVICE,
  payload: service,
});

export const updateSmsService = (service) => ({
  type: UPDATE_SMS_SERVICE,
  payload: service,
});

export const deleteSmsService = (id) => ({
  type: DELETE_SMS_SERVICE,
  payload: id,
});

export const fetchSmsServices = (params) => async (dispatch) => {
  dispatch(setIsSmsServicesLoading(true));

  try {
    const response = await apiHelper('get', '/api/sms-service', '', params);
    if (response?.data) {
      const { paginatedResult, totalCount } = response.data;
      dispatch(setSmsTotalCount(totalCount));
      dispatch(setSmsServices(paginatedResult));
      dispatch(setIsSmsServicesLoading(false));
    }
  } catch (error) {
    console.error(error);
    dispatch(setIsSmsServicesLoading(false));
  }
};

export const fetchIntegratedSmsServices = () => async (dispatch) => {
  try {
    const response = await apiHelper('get', '/api/sms-service/integrated-services', '');
    if (response?.data) {
      dispatch(setIntegratedServices(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchSmsAuthDataTypes = () => async (dispatch) => {
  try {
    const response = await apiHelper('get', '/api/sms-service/get-auth-data-types', '');
    if (response?.data) {
      dispatch(setAuthDataTypes(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchSmsServiceById = (id) => async (dispatch) => {
  try {
    const response = await apiHelper('get', `/api/sms-service/${id}`, '');
    if (response?.data) {
      dispatch(updateSmsService(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchSmsServiceByAgentId = (id) => async (dispatch) => {
  try {
    const response = await apiHelper('get', `/api/sms-service/get-agent-services/${id}`, '');
    if (response?.data) {
      dispatch(setCrmUserSmsServices(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const createSmsService = (serviceData, hideModal) => async (dispatch) => {
  try {
    const response = await apiHelper('post', '/api/sms-service', serviceData);
    if (response?.data) {
      dispatch(addSmsService(response.data));
      toast.success('Updated successfully');
      hideModal();
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.message);
    hideModal();
  }
};

export const updateSmsServiceById = (id, serviceData, setIsSending) => async (dispatch) => {
  try {
    const response = await apiHelper('put', `/api/sms-service/${id}`, serviceData);
    if (response?.data) {
      dispatch(updateSmsService(response.data));
      toast.success('Updated successfully');
      setIsSending(false);
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.message);
  }
};

export const deleteSmsServiceById = (id) => async (dispatch) => {
  try {
    await apiHelper('delete', `/api/sms-service/${id}`, '');
    dispatch(deleteSmsService(id));
  } catch (error) {
    console.error(error);
  }
};

export const exportUsersToFile = (queryData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/sms-service/export-table', queryData);

    if (res.data && res.data.fileUrl) {
      window.open(res.data.fileUrl);
    } else {
      toast.error('File was not created. Please, contact our support team.', {
        autoClose: 1000,
      });
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.message, {
      autoClose: 1000,
    });
  }
};


export const setStatusSmsServiceById = (id, status) => async (dispatch) => {
  try {
    const response = await apiHelper(
      'post',
      `/api/sms-service/setActiveService/${id}`,
      { status },
    );

    if (response?.data) {
      dispatch(updateSmsService(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const sendSmsAction = (data, handleClose, userId, crmUserId, crmUserName) => async (dispatch) => {
  try {
    const response = await apiHelper('post', '/api/sms-service/send-sms', data);
    if (response?.data) {
      await dispatch(createUserSmsHistory({
        user: userId,
        agent: crmUserId,
        agentName: crmUserName,
      }));

      toast.success('SMS sent successfully');
      handleClose();
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.message);
  }
};
