import {
  SHOW_ALL_ADMIN_COMMENTS, GET_ADMIN_COMMENTS, ADD_ADMIN_COMMENT, EDIT_ADMIN_COMMENT, DELETE_ADMIN_COMMENT, DELETE_ADMIN_COMMENT_BY_USERID, TOGGLE_STATE,
  ERROR_STATE,
} from './adminCommentTypes';

const initialState = {
  adminComments: [],
  newComment: {},
  userIds: [],
  success: false,
  fetched: false,
  newCommentAdded: false,
  commentEditted: false,
  commentDeleted: false,
  error: false,
}; 

// eslint-disable-next-line default-param-last
const adminCommentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALL_ADMIN_COMMENTS:
      return {
        ...state,
        adminComments: action.payload,
        success: false,
        fetched: true,
      };
    case GET_ADMIN_COMMENTS:
      return {
        ...state,
        adminComments: action.payload,
        fetched: true,
        newCommentAdded: false,
        commentDeleted: false,
      };
    case ADD_ADMIN_COMMENT:
      const pinnedComments = state.adminComments.filter((comment) => comment.isPinned);
      const otherComments = state.adminComments.filter((comment) => !comment.isPinned);
      return {
        ...state,
        adminComments: [...pinnedComments, action.payload, ...otherComments],
        newCommentAdded: true,
        fetched: false,
        commentDeleted: false,
      };
    case DELETE_ADMIN_COMMENT:
      return {
        ...state,
        adminComments: state.adminComments.filter((item) => item._id !== action.payload),
        commentDeleted: true,
        newCommentAdded: false,
        fetched: false,
      };
    case DELETE_ADMIN_COMMENT_BY_USERID:
      return {
        ...state,
        userIds: action.payload.userIds,
        adminComments: state.adminComments.filter((item) => !item.userIds.has(item.userId)),
        commentDeleted: true,
        newCommentAdded: false,
        fetched: false,
      };
    case EDIT_ADMIN_COMMENT: {
      const index = state.adminComments.findIndex((obj) => obj._id === action.payload?._id);
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      index !== -1 ? state.adminComments[index] = action.payload : null;
      return {
        ...state,
        adminComments: state.adminComments,
        commentEditted: true,
      };
    }
    case TOGGLE_STATE:
      return {
        ...state,
        success: false,
        fetched: false,
        newCommentAdded: false,
        commentEditted: false,
        error: false,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default adminCommentReducer;
