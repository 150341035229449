import React from 'react';

/**
 * 
 * @param {*} status 'idle' | 'filled'
 * @returns 
 */
export function FilterTableIcon({ status = 'idle' }) {
  const fillColor = status === 'idle' ? '#8E9099' : '#ffffff';

  return (
    <svg width="12" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
      <path d="M5.5 9.5H8.5V8H5.5V9.5ZM0.25 0.5V2H13.75V0.5H0.25ZM2.5 5.75H11.5V4.25H2.5V5.75Z" fill={fillColor} />
    </svg>
  );
}
