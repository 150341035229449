/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  REGISTER_USER, LOGIN_ADMIN, REGISTER_SUB_ADMIN, REGISTER_ADMIN, TOGGLE_AUTH_STATE, ERROR_STATE, TWO_FA_STATUS, TWO_FA_QR_IMAGE, TWO_FA_STATUS_CHANGED, TOGGLE_TWO_FA_STATE, TWO_FA_ERROR_STATE,
} from './authTypes';
import { ENV } from '../../config/config';
import { apiHelper } from '../apiHelper';
import { login } from '../../services/navigatorService';


export const RegisterUser = (user) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/register', user);
    if (res?.data) {
      const { data } = res;
      toast.success('User created successfully');
      dispatch({
        type: REGISTER_USER,
        payload: data,
      });
    } else {
      dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const RegisterSubAdmin = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/register-subadmin', data);
    if (res?.data) {
      const { data } = res;
      toast.success('Sub Admin created successfully');
      dispatch({
        type: REGISTER_SUB_ADMIN,
        payload: data,
      });
    } else {
      dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const registerAdmin = (body) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/register-admin', body);
    if (res?.data) {
      const { data } = res;
      toast.success(data?.message);
      dispatch({
        type: REGISTER_ADMIN,
        payload: data,
      });
    } else {
      dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const adminLoginByToken = (token) => async (dispatch) => {
  try {
    const res = await axios({
      method: 'get',
      url: `${`${ENV.serverUrl}/api/auth/login-by-auth-token`}`,
      headers: {
        Authorization: token,
      },
    });

    if (res && res.data && res.data.success) {
      const { data } = res;

      ENV.encryptTempUserData(data.user, data.token, data.user._id);
      
      dispatch({
        type: LOGIN_ADMIN,
        payload: data,
      });

      login();
    }
  } catch (error) {
    console.log(error);
  }
};

export const adminLogin = (user) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/admin-login', user);
    if (res?.data) {
      const { data } = res;
      ENV.encryptUserData(data.user, data.token, data.user._id);
      dispatch({
        type: LOGIN_ADMIN,
        payload: data,
      });

      login();
    }
  // eslint-disable-next-line no-empty
  } catch (error) {
  }
};

export const setupTwoFA = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/auth/admin/setup-two-factor-authentication/${userId}`);
    if (res?.data) {
      const { data } = res;

      dispatch({
        type: TWO_FA_QR_IMAGE,
        payload: data?.tfaQRImage ? data?.tfaQRImage : '',
      });
    }
  } catch (error) {
    dispatch({
      type: TWO_FA_ERROR_STATE,
    });
    // console.log(error.response.message);
  }
};

export const checkTwoFAStatus = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/auth/admin/get-two-factor-authentication/${userId}`);
    if (res?.data) {
      const { data } = res;

      dispatch({
        type: TWO_FA_STATUS,
        payload: data?.tfa_auth_status,
      });
    }
  } catch (error) {
    dispatch({
      type: TWO_FA_ERROR_STATE,
    });
    // console.log(error.response.message);
  }
};

export const enableTwoFAStatus = (bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/admin/enable-two-factor-authentication', bodyData);

    if (res?.data && res?.data?.success) {
      const { data } = res;

      dispatch({
        type: TWO_FA_STATUS_CHANGED,
      });
      dispatch({
        type: TWO_FA_STATUS,
        payload: data?.tfa_auth_status,
      });
      toast.success(data.message);
    } else {
      await dispatch({
        type: TWO_FA_ERROR_STATE,
      });
      toast.error(res?.data?.message);
    }
  } catch (error) {
    await dispatch({
      type: TWO_FA_ERROR_STATE,
    });
    toast.error(error.response.message);
  }
};

export const disableTwoFAStatus = (bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/admin/disable-two-factor-authentication', bodyData);

    if (res?.data && res?.data?.success) {
      const { data } = res;

      dispatch({
        type: TWO_FA_STATUS_CHANGED,
      });
      dispatch({
        type: TWO_FA_STATUS,
        payload: data?.tfa_auth_status,
      });
      toast.success(data.message);
    } else {
      await dispatch({
        type: TWO_FA_ERROR_STATE,
      });
      toast.error(res?.data?.message);
    }
  } catch (error) {
    dispatch({
      type: TWO_FA_ERROR_STATE,
    });
    toast.error(error.response.message);
  }
};

export const updateTwoFAState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_TWO_FA_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

export const updateAuthState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_AUTH_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
