import React, { useEffect, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useSelector } from 'react-redux';
import { GeoChart } from '../Charts/GeoChart/GeoChart';
import { CellWithProgressBar } from '../components/CellWithProgressBar';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';
import style from './CountriesFTD.module.css';
import { selectAffiliatesDashboardCountryMapData, selectAffiliatesDashboardStatisticByCountry, selectAffiliatesDashboardTotalFtdByCountry } from '../../../redux/affiliatesDashboard/affiliatesDashboardSelectors';

function CountriesFTD({ downloadDepositsByCountryHandler }) {
  const countryData = useSelector(selectAffiliatesDashboardCountryMapData);
  const statisticsByCountry = useSelector(selectAffiliatesDashboardStatisticByCountry);
  const totalFtdDeposits = useSelector(selectAffiliatesDashboardTotalFtdByCountry);
  
  if (countryData === null || statisticsByCountry === null) {
    return <>Loading...</>;
  }

  return (
    <div className={style.wrapper}>
      <div className={style.titleContainer}>
        <h3 className={style.title}>Count by countries</h3>
        {/* <button type="button" onClick={downloadDepositsByCountryHandler} className={style.downloadBtn}>
          <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} />
        </button> */}
      </div>
      <div className={style.chartsContainer}>
        <div className={style.mapChartWrapper}>
          <div className={style.mapChartMapContainer}>
            <GeoChart data={countryData} />
          </div>
          <div className={style.allDepositTotal}>
            <span className={style.totalAmountTitle}>Total:</span>
            <span className={style.totalAmount}>{totalFtdDeposits}</span>
          </div>
        </div>
        <div className={style.allDepositsStatisticsTable}>
          {(statisticsByCountry && statisticsByCountry.length > 0)
            ? statisticsByCountry
              .sort((a, b) => a.country.localeCompare(b.country))
              .map((el) => (
                <CellWithProgressBar 
                  key={el.country}
                  name={el.country} 
                  countFTD={el.countFTD} 
                  percent={el.percent?.toFixed(2)}
                  withFlag
                  flag={el.isoCountryCode}
                />
              ))
            : (
              <div className={style.noDataWrapper}>
                <NoDataAvailableTableIcon />
                <p className={style.noDataWrapperTitle}>No data available</p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default CountriesFTD;
