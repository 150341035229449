import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FormControlWithRadioButtons } from '../../SalesDashboard/components/FormControl';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';
import style from './StatusFTD.module.css';
import { selectAffiliatesDashboardColorDataByStatus, selectAffiliatesDashboardDataByStatus, selectStatusOption } from '../../../redux/affiliatesDashboard/affiliatesDashboardSelectors';
import { setStatusOption } from '../../../redux/affiliatesDashboard/affiliatesDashboardActions';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { DoughnutChart } from '../Charts/DoughnutChart/DoughnutChart';

function StatusFTD({ downloadDepositsByStatusHandler }) {
  const dispatch = useDispatch();
  const [screenWidth, screenHeight] = useWindowSize();

  const donutData = useSelector(selectAffiliatesDashboardDataByStatus);
  const colorData = useSelector(selectAffiliatesDashboardColorDataByStatus);
  const selectedOption = useSelector(selectStatusOption);
  const isMobile = screenWidth <= 499;

  const options = [
    { ticker: 'All Statuses' },
    { ticker: 'FTD' },
  ];

  const changeStatusOption = (option) => {
    dispatch(setStatusOption(option));
  };

  return (
    <div className={style.tableWrapper}>
      <div className={style.allDepositsTitleContainer}>
        <h3 className={style.allDepositsTitle}>Leads/clients status</h3>
        {/* <button type="button" onClick={downloadDepositsByStatusHandler} className={style.allDepositsAnalysisBtn}>
          <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} />
        </button> */}
      </div>
      <div>
        <FormControlWithRadioButtons options={options} selectedOptions={selectedOption} setSelectedOptions={changeStatusOption} />

        {
          (donutData?.length > 1) 
            ? colorData && <DoughnutChart data={donutData} colors={colorData} isMobile={isMobile} />
            : ( 
              <div className={style.noDataWrapper}>
                <NoDataAvailableTableIcon />
                <p className={style.noDataWrapperTitle}>No data available</p>
              </div>
            )
        } 
      </div>
    </div>
  );
}

export default StatusFTD;
