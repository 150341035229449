import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { sendSmsAction } from '../../../../redux/smsServices/smsServicesActions';
import s from './SendSmsModal.module.css';
import { selectCrmUserSelectedSmsService } from '../../../../redux/smsServices/smsServicesSelectors';

function SendSmsModal({ userId, handleClose, toPhoneNumber }) {
  const dispatch = useDispatch();

  const crmUserSelectedSmsService = useSelector(selectCrmUserSelectedSmsService);
  const crmUser = useSelector((state) => state.crmUser.crmUserInfo);

  const [subject, setSubject] = useState('Stage Support Team');
  const [smsText, setSmsText] = useState('');
  
  const handleSubjectChange = (event) => setSubject(event.target.value);
  const handleSmsTextChange = (event) => setSmsText(event.target.value);

  const handleSubmitSms = () => {
    if (crmUserSelectedSmsService) {
      dispatch(sendSmsAction(
        {
          serviceId: crmUserSelectedSmsService._id,
          smsText,
          toPhoneNumber,
        },
        handleClose,
        userId,
        crmUser._id,
        `${crmUser.firstName} ${crmUser.lastName}`,
      ));
    } else {
      toast.error('No SMS configuration selected');
    }
  };

  return (
    <>
      <div className={s.wrapper}>
        {/* <div className="form-group me-2">
          <label className="control-label mb-2">Subject</label>
          <div className="input-wrapper">
            <input 
              type="text" 
              className="form-control" 
              name="smsSubject" 
              value={subject} 
              onChange={handleSubjectChange}
            />
          </div>
        </div> */}
        <div className="form-group me-2">
          <label className="control-label mb-2">SMS Body</label>
          <div className="input-wrapper">
            <input
              type="text" 
              className="form-control" 
              name="smsText" 
              value={smsText} 
              onChange={handleSmsTextChange}
            />
          </div>
        </div>
      </div>
      <div className={s.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmitSms}>
          Send SMS
        </Button>
      </div>
    </>
  );
}

export default SendSmsModal;
