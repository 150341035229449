import React, { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { ChipsButtonComponent } from './components/ChipsButtonComponent';
import { DatePickerComponent } from './components/DatePickerComponent';
import { getDateRange, getTimeBoundPeriodOptions } from '../utils/timelineHelper';
import { SALES_STATUSES, salesStatusOptions } from '../utils/optionsHelper';
import { getBrands } from '../../../redux/brand/brandActions';
import { getDesksIbs } from '../../../redux/crmUser/crmUserActions';
import {
  fetchAffiliatesDashboardAnalysis, fetchFTDByCountry, fetchFTDByStatus, fetchByAllStatuses,
} from '../../../redux/affiliatesDashboard/affiliatesDashboardActions';
import { selectStatusOption } from '../../../redux/affiliatesDashboard/affiliatesDashboardSelectors';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { SearchableCheckboxList } from '../../SalesDashboard/components/SearchableCheckboxList';
import styles from './AffiliateDashboardFilter.module.css';

export function AffiliateDashboardFilter({
  isOpen, setIsOpen, filterObject, setFilterObject, 
}) {
  const dispatch = useDispatch();

  const [screenWidth, screenHeight] = useWindowSize();

  const affiliateDashboardFilterStorage = 'Affiliate_Dashboard/filters'; 

  const [amountOfFilters, setAmountOfFilters] = useState(0);
  
  const [timeBoundPeriodOptions, setTimeBoundPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [fromDate, setFromDate] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toDate, setToDate] = useState('');
  const [toTime, setToTime] = useState('');

  const [selectedSalesStatus, setSelectedSalesStatus] = useState(SALES_STATUSES.BOTH);
  const [selectedBrand, setSelectedBrand] = useState('all');
  const [desksIbsItems, setDesksIbsItems] = useState(new Set());

  const brands = useSelector((state) => state.brands);
  const desks = useSelector((state) => state.crmUser.deskIbs);
  const statusOption = useSelector(selectStatusOption);

  function getFilteredObjectLength(obj, keysToExclude) {
    return Object.keys(obj).reduce((count, key) => (keysToExclude[key] === obj[key] ? count : count + 1), 0);
  }

  useEffect(() => {
    localStorage.setItem(affiliateDashboardFilterStorage, JSON.stringify(filterObject));
    setAmountOfFilters(getFilteredObjectLength(filterObject, { period: 'All', managerStatus: 3, brand: 'all' }));
  }, [filterObject]);

  const setStoredFilterData = () => {
    const affiliateDashboardStorage = localStorage.getItem(affiliateDashboardFilterStorage);

    if (affiliateDashboardStorage) {
      const filters = JSON.parse(affiliateDashboardStorage);
      const period = typeof filters?.period === 'string' ? 'All' : (!filters?.period ? 'All' : (filters?.period?.[2] || null));
      
      setSelectedPeriod(period);
      setSelectedSalesStatus(filters?.managerStatus || SALES_STATUSES.BOTH);
      setSelectedBrand(filters?.brand || 'all');
    }
  };

  const changePeriodHandler = (period) => {
    if (period.label === selectedPeriod && period.label !== 'All') {
      setSelectedPeriod('All');
      setFilterObject((prevState) => ({ ...prevState, period: 'All' }));
    } else {
      if (period.label === 'All') { 
        setFilterObject((prevState) => ({ ...prevState, period: 'All' }));

        setFromDate('');
        setFromTime('');
        setToDate('');
        setToTime('');
      }
      if (period.label !== 'All' && period.label !== 'Custom') {
        const fromDateObj = new Date(period.dateRange[0]);
        const toDateObj = new Date(period.dateRange[1]);

        const fromDate = fromDateObj.toISOString().split('T')[0];
        const fromHourTime = fromDateObj.toISOString().split('T')[1].split(':')[0];
        const fromMinuteTime = fromDateObj.toISOString().split('T')[1].split(':')[1];
        const fromTime = `${fromHourTime}:${fromMinuteTime}`;

        const toDate = toDateObj.toISOString().split('T')[0];
        const toHourTime = toDateObj.toISOString().split('T')[1].split(':')[0];
        const toMinuteTime = toDateObj.toISOString().split('T')[1].split(':')[1];
        const toTime = `${toHourTime}:${toMinuteTime}`;

        setFromDate(fromDate);
        setFromTime(fromTime);
        setToDate(toDate);
        setToTime(toTime);

        setFilterObject((prevState) => ({ ...prevState, period: [...period.dateRange, period.label] }));
      }

      if (period.label === 'Custom') {
        let fromDateFormatted;
        let toDateFormatted;
    
        if (fromDate.length && fromTime.length) {
          const date = new Date(`${fromDate}T${fromTime}:00Z`);
          fromDateFormatted = date.toISOString();
        }

        if (toDate.length && toTime.length) {
          const date = new Date(`${toDate}T${toTime}:00Z`);
          toDateFormatted = date.toISOString();
        }

        if (fromDateFormatted && toDateFormatted) {
          setFilterObject((prevState) => ({ ...prevState, period: [fromDateFormatted, toDateFormatted, 'Custom'] }));
        }
      }
  
      setSelectedPeriod(period.label);
    }
  };

  useEffect(() => {
    if (selectedPeriod !== 'Custom') {
      if (selectedPeriod !== 'All' && selectedPeriod !== null) {
        const { startDate, endDate } = getDateRange(selectedPeriod);
        const fromDateObj = new Date(startDate);
        const toDateObj = new Date(endDate);

        const fromDate = fromDateObj.toISOString().split('T')[0];
        const fromHourTime = fromDateObj.toISOString().split('T')[1].split(':')[0];
        const fromMinuteTime = fromDateObj.toISOString().split('T')[1].split(':')[1];
        const fromTime = `${fromHourTime}:${fromMinuteTime}`;

        const toDate = toDateObj.toISOString().split('T')[0];
        const toHourTime = toDateObj.toISOString().split('T')[1].split(':')[0];
        const toMinuteTime = toDateObj.toISOString().split('T')[1].split(':')[1];
        const toTime = `${toHourTime}:${toMinuteTime}`;

        setFromDate(fromDate);
        setFromTime(fromTime);
        setToDate(toDate);
        setToTime(toTime);
      }
      return;
    }

    let fromDateFormatted;
    let toDateFormatted;
    
    if (fromDate.length && fromTime.length) {
      const date = new Date(`${fromDate}T${fromTime}:00Z`);
      fromDateFormatted = date.toISOString();
    }

    if (toDate.length && toTime.length) {
      const date = new Date(`${toDate}T${toTime}:00Z`);
      toDateFormatted = date.toISOString();
    }

    if (fromDateFormatted && toDateFormatted) {
      setFilterObject((prevState) => ({ ...prevState, period: [fromDateFormatted, toDateFormatted, 'Custom'] }));
    }
  }, [fromDate, fromTime, toTime, toDate, selectedPeriod]);

  const changeSalesStatusHandler = (salesStatus) => {
    if (salesStatus.value === selectedSalesStatus && salesStatus.value !== SALES_STATUSES.BOTH) {
      setSelectedSalesStatus(SALES_STATUSES.BOTH);
      setFilterObject((prevState) => ({ ...prevState, managerStatus: SALES_STATUSES.BOTH }));
    } else {
      setSelectedSalesStatus(salesStatus.value);
      setFilterObject((prevState) => ({ ...prevState, managerStatus: salesStatus.value }));
    }
  };

  const changeBrandHandler = (brandItem) => {
    if (brandItem._id === selectedBrand && brandItem._id !== 'all') {
      setSelectedBrand('all');
      setFilterObject((prevState) => ({ ...prevState, brand: 'all' }));
    } else {
      setSelectedBrand(brandItem._id);
      setFilterObject((prevState) => ({ ...prevState, brand: brandItem._id }));
    }
  };

  const handleChangeDeskIBs = (id) => {
    const newSelectedItems = new Set(desksIbsItems);

    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }

    setDesksIbsItems(newSelectedItems);
    setFilterObject((prevState) => ({ ...prevState, deskRole: [...newSelectedItems] }));
  };

  useEffect(() => {
    if (desksIbsItems.size === 0) {
      const selectedDesks = new Set(filterObject?.deskRole || []);
      setDesksIbsItems(selectedDesks);
    }
  }, [filterObject]);

  const deleteFilterHandler = () => {
    setSelectedPeriod('All');

    setFromDate('');
    setFromTime('');
    setToDate('');
    setToTime('');

    setSelectedSalesStatus(SALES_STATUSES.BOTH);
    setSelectedBrand('all');

    setFilterObject({});
    setAmountOfFilters(0);

    localStorage.removeItem(affiliateDashboardFilterStorage);
  };

  const refreshFilterHandler = () => {
    dispatch(fetchAffiliatesDashboardAnalysis(filterObject));
    dispatch(fetchFTDByCountry(filterObject));
    dispatch(statusOption === 'All Statuses' ? fetchByAllStatuses(filterObject) : fetchFTDByStatus(filterObject));
  };

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getDesksIbs());

    const options = getTimeBoundPeriodOptions();

    setTimeBoundPeriodOptions(options);
    setStoredFilterData();
  }, []);

  const isMobile = screenWidth <= (905 + 220);

  if (!isOpen && isMobile) return '';

  return (
    <div className={`${styles.filterWrapper} ${isMobile && styles.mobileFilterWrapper}`}>
      <div className={styles.filterDashboardWrapper}>
        <div className={styles.filterDashboardContainer}>
          <div className={styles.filterDashboard}>
            <div className={styles.filterDashboardHeader}>
              {
              isMobile && (
                <div className={styles.filterDashboardHeaderSaveBtnWrapper}>
                  <CloseIcon
                    sx={{
                      color: '#C4C6D0',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsOpen(false)}
                  />
                </div>
              )
            }
              <div className={styles.filterDashboardHeaderDescription}>
                <p className={styles.filterDashboardHeaderTitle}>
                  Filter Dashboard
                </p>
                <p className={styles.filterDashboardHeaderText}>
                  Select a filter to control which data show in this dashboard
                </p>
              </div>
              {
                isMobile && (
                  <div className={styles.filterDashboardHeaderSaveBtnWrapper}>
                    <button
                      type="button"
                      onClick={() => setIsOpen(false)}
                    >
                      Save
                    </button>
                  </div>
                )
              }
            </div>
            <div className={styles.filterDashboardPeriodContainer}>
              <p className={styles.filterDashboardPeriodTitle}>Period</p>
              <div className={styles.filterDashboardPeriodChips}>
                {
                  timeBoundPeriodOptions.map((el) => (
                    <ChipsButtonComponent
                      key={el.label} 
                      label={el.label}
                      isSelected={selectedPeriod === el.label}
                      onClick={() => changePeriodHandler(el)}
                    />
                  ))
                }
              </div>
              <DatePickerComponent 
                valueDatePicker={fromDate} 
                labelTitle="From" 
                datePickerId="dateFrom"
                timePickerId="timeFrom"
                valueTimePicker={fromTime}
                setDate={setFromDate}
                setTime={setFromTime}
                disabled={selectedPeriod !== 'Custom'}
              />
              <DatePickerComponent 
                valueDatePicker={toDate} 
                labelTitle="To" 
                datePickerId="dateTo"
                timePickerId="timeTo"
                valueTimePicker={toTime}
                setDate={setToDate}
                setTime={setToTime}
                disabled={selectedPeriod !== 'Custom'}
              />
            </div>
            <div className={styles.dividerWrapper}>
              <div className={styles.divider} />
            </div>
            <div className={styles.filterDashboardPeriodContainer}>
              <p className={styles.filterDashboardPeriodTitle}>Sales status:</p>
              <div className={styles.filterDashboardPeriodChips}>
                {
                  salesStatusOptions.map((el) => (
                    <ChipsButtonComponent
                      key={el.label}
                      label={el.label}
                      isSelected={selectedSalesStatus === el.value}
                      onClick={() => changeSalesStatusHandler(el)}
                    />
                  ))
                }
              </div>
            </div>
            <div className={styles.dividerWrapper}>
              <div className={styles.divider} />
            </div>
            <div className={styles.filterDashboardPeriodContainer}>
              <p className={styles.filterDashboardPeriodTitle}>Brand:</p>
              <div className={styles.filterDashboardPeriodChips}>
                <ChipsButtonComponent label="All" isSelected={selectedBrand === 'all'} onClick={() => changeBrandHandler({ _id: 'all' })} />
                {
                  brands?.map((el) => (
                    <ChipsButtonComponent
                      key={el._id}
                      label={el.name}
                      isSelected={selectedBrand === el._id}
                      onClick={() => changeBrandHandler(el)}
                    />
                  ))
                }
              </div>
            </div>
            <div className={styles.dividerWrapper}>
              <div className={styles.divider} />
            </div>
            <div className={styles.filterDropDownContainer}>
              <p className={styles.filterDashboardPeriodTitle}>Desks/IBs:</p>
              <div className={styles.filterDropDown}>
                {
                  desks.length > 0 && (
                    <SearchableCheckboxList
                      data={desks}
                      selectedItems={desksIbsItems}
                      setSelectedItems={setDesksIbsItems}
                      handleChange={handleChangeDeskIBs}
                      setFilterObject={setFilterObject}
                    />
                  ) 
                }
              </div> 
            </div>
            <div className={styles.spacing} />
          </div>
      
          <div className={styles.filterDashboardFooter}>
            <p className={styles.filterDashboardFooterFilterText}>
              Filter:
              {amountOfFilters}
            </p>
            <div className={styles.filterDashboardFooterBtnsContainer}>
              <button
                type="button"
                disabled={amountOfFilters === 0}
                className={styles.filterDashboardFooterBtn}
                onClick={deleteFilterHandler}
              >
                <DeleteOutlineIcon sx={{ color: '#C4C6D0' }} />
              </button>
              <button
                type="button"
                className={styles.filterDashboardFooterBtn}
                onClick={refreshFilterHandler}
              >
                <RefreshIcon sx={{ color: '#C4C6D0' }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
