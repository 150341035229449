import {
  SET_SMS_SERVICES,
  ADD_SMS_SERVICE,
  UPDATE_SMS_SERVICE,
  DELETE_SMS_SERVICE,
  SET_IS_SERVICES_LOADING,
  SET_DESCRIPTION_FILTER,
  SET_SERVICE_NAME_FILTER,
  SET_IS_ACTIVE_FILTER,
  SET_AGENT_FILTER,
  SET_INTEGRATED_SERVICES,
  SET_AUTH_DATA_TYPES,
  SET_TOTAL_COUNT,
  SET_CRM_USER_SERVICES,
  SET_CRM_USER_SELECTED_SERVICE,
} from './smsServicesTypes';

const initialState = {
  descriptionFilter: '',
  serviceNameFilter: '',
  isActiveFilter: '',
  agentFilter: '',
  totalCount: 0,
  isServicesLoading: false,
  integratedServices: [],
  authDataTypes: [],
  services: [],
  crmUserServices: [],
  crmUserSelectedService: null,
};

const smsServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CRM_USER_SERVICES:
      return {
        ...state,
        crmUserServices: action.payload,
      };
    case SET_CRM_USER_SELECTED_SERVICE:
      return {
        ...state,
        crmUserSelectedService: action.payload,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_INTEGRATED_SERVICES:
      return {
        ...state,
        integratedServices: action.payload,
      };
    case SET_AUTH_DATA_TYPES:
      return {
        ...state,
        authDataTypes: action.payload,
      };
    case SET_DESCRIPTION_FILTER:
      return {
        ...state,
        descriptionFilter: action.payload,
      };
    case SET_SERVICE_NAME_FILTER:
      return {
        ...state,
        serviceNameFilter: action.payload,
      };
    case SET_IS_ACTIVE_FILTER:
      return {
        ...state,
        isActiveFilter: action.payload,
      };
    case SET_AGENT_FILTER:
      return {
        ...state,
        agentFilter: action.payload,
      };
    case SET_IS_SERVICES_LOADING:
      return {
        ...state,
        isServicesLoading: action.payload,
      };
    case SET_SMS_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case ADD_SMS_SERVICE:
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    case UPDATE_SMS_SERVICE:
      return {
        ...state,
        services: state.services.map((service) => (service._id === action.payload._id ? action.payload : service)),
      };
    case DELETE_SMS_SERVICE:
      return {
        ...state,
        services: state.services.filter((service) => service._id !== action.payload),
      };
    default:
      return state;
  }
};

export default smsServicesReducer;
