import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  withRouter, Link, useHistory, useLocation, 
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Row, Col, Nav, Tab,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/RelateXpertLogo.png';
import { adminLogin, adminLoginByToken } from '../../redux/auth/authActions';
import { setNavigator } from '../../services/navigatorService';

function LoginPage() {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const history = useHistory();

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const userData = {
    email: {
      required: 'Email is required',
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
      },
    },
    password: {
      required: 'Password is required',
    },
  };

  useEffect(() => {
    setNavigator(history);
  }, [history]);

  useEffect(() => {
    const authToken = params.get('auth_token');

    if (authToken) dispatch(adminLoginByToken(authToken));
  }, [params]);

  const handleSave = async (formData) => {
    const data = {
      email: formData.email,
      password: formData.password,
      one_time_password: formData.one_time_password,
    };
    dispatch(adminLogin(data));
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="col-lg-12 col-md-12 login-page p-0">
      <div className="content-wrapper">
        <div className="login-section new-login-sec">
          <div className="image-section" />
          <div className="login-data">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">LOGIN</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="logo">
                        <Link to="/"><img alt="" className="img-fluid" src={logo} /></Link>
                      </div>
                      <form onSubmit={handleSubmit(handleSave)}>
                        <div className="form-group col-md-12">
                          <label className="control-label mb-2">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            {...register('email', userData.email)}
                            name="email"
                          />
                          {errors?.email && <span className="errMsg">{errors.email.message}</span>}
                        </div>
                        <div className="form-group col-md-12 pt-2">
                          <label className="control-label mb-2">Password</label>
                          <div style={{ position: 'relative' }}>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              className="form-control"
                              placeholder="Enter password"
                              {...register('password', userData.password)}
                              name="password"
                            />
                            <FontAwesomeIcon
                              icon={showPassword ? faEye : faEyeSlash}
                              color="white"
                              onClick={() => handleShowPassword()}
                              style={{ position: 'absolute', right: 10, top: 15 }}
                            />
                          </div>
                          {errors?.password && <span className="errMsg">{errors.password.message}</span>}
                        </div>
                        <div className="form-group col-md-12 pt-2">
                          <label className="control-label mb-2">2FA code (if enabled)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="2FA code"
                            {...register('one_time_password', userData.one_time_password)}
                            name="one_time_password"
                          />
                          {errors?.one_time_password && <span className="errMsg">{errors.one_time_password.message}</span>}
                        </div>
                        <div className="login-page-buttons mt-2">
                          <button className="btn-default w-100 btn mt-2" type="submit">Login</button>
                        </div>
                        <br />
                        <div className="text-center">
                          <Link to="/forgot-password-email">Forgot Password ?</Link>
                        </div>
                        <br />
                      </form>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(LoginPage);
