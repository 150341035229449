import React, { useState, useRef } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from '../SalesDashboardFilter/SalesDashboardFilter.module.css';
import { prettyCutOutputWidth } from '../../AffiliatesDashboard/utils/helpers';
import { useClickOutside } from '../../../hooks/useClickOutside';

export function SearchableCheckboxList({
  data, selectedItems, setSelectedItems, handleChange, setFilterObject,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownDirection, setDropdownDirection] = useState('downwards');
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside({
    onClickOutside: () => setIsOpen(false),
    ref: dropdownRef,
  });

  const handleSelectAll = () => {
    const newSelectedItems = new Set(data.map((item) => item._id));
    setSelectedItems(newSelectedItems);
    setFilterObject((prevState) => ({ ...prevState, deskRole: [...newSelectedItems] }));
  };

  const handleDeselectAll = () => {
    setSelectedItems(new Set());
    setFilterObject((prevState) => ({ ...prevState, deskRole: [] }));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      const button = buttonRef.current;
      const rect = button.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 280;
      if (spaceBelow < dropdownHeight && rect.top > dropdownHeight) {
        setDropdownDirection('upwards');
      } else {
        setDropdownDirection('downwards');
      }
    }
  };

  const filteredData = data.filter((item) => {
    const fullName = `${item.firstName} ${item.lastName}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className={styles.searchableCheckBoxListWrapper}>
      <button 
        ref={buttonRef} 
        type="button" 
        onClick={toggleDropdown} 
        className={`${(selectedItems.size > 0)
          ? styles.searchableCheckBoxListWrapperBtnSelected 
          : styles.searchableCheckBoxListWrapperBtn}`}
      >
        {isOpen 
          ? (
            <span className={styles.searchableCheckBoxListWrapperBtnSpan}>
              {selectedItems.size > 0 && <DoneIcon />}
              Desks/IBs
              <ArrowDropUpIcon />  
            </span>
          ) 
          : (
            <span className={styles.searchableCheckBoxListWrapperBtnSpan}>
              {selectedItems.size > 0 && <DoneIcon />}
              Desks/IBs
              <ArrowDropDownIcon />
            </span>
          )}
      </button>
      {isOpen && (
        <div ref={dropdownRef} className={`${styles.searchableCheckBoxListDropdown} ${dropdownDirection === 'upwards' ? styles.upwards : ''}`}>
          <input
            type="text"
            className={styles.searchableCheckBoxListInput}
            placeholder="Desks/IBs search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className={styles.searchableCheckBoxListBtnsContainer}>
            <button type="button" onClick={handleSelectAll} className={styles.searchableCheckBoxListBtns}>Select All</button>
            <button type="button" onClick={handleDeselectAll} className={styles.searchableCheckBoxListBtns}>Deselect All</button>
          </div>
          <hr className={styles.searchableCheckBoxListHr} />
          <ul className={styles.searchableCheckBoxListUl}>
            {filteredData.length ? filteredData.map((item) => (
              <li key={item._id} className={styles.searchableCheckBoxListLI}>
                <label className={styles.searchableCheckBoxListLabel}>
                  {prettyCutOutputWidth(`${item.firstName} ${item.lastName}`, 25)}
                  <input
                    type="checkbox"
                    className={styles.searchableCheckBoxListCheckbox}
                    checked={selectedItems.has(item._id)}
                    onChange={() => handleChange(item._id)}
                  />
                </label>
              </li>
            )) : (
              <li className={styles.searchableCheckBoxListLI}>
                <label className={styles.searchableCheckBoxListLabel}>
                  No data
                </label>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
