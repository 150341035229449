import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ChangeTransactionDataModalButton({ data, handleSave }) {
  const [show, setShow] = useState(false);
  const [isResolved, setIsResolved] = useState(data.isResolved);
  const [changedData, setChangedData] = useState({});

  const handleOpen = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setIsResolved(data.isResolved); 
  };

  const handleChangeData = (key, value) => {
    setChangedData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSaveData = () => {
    handleSave({
      _id: data._id,
      ...changedData,
    });
    setChangedData({});
    setShow(false);
  };

  return (
    <>
      <button
        type="button"
        title="Change Data"
        className="btn btn-danger btn-sm me-1 p-1"
        onClick={handleOpen}
      >
        Change
      </button>
      <Modal show={show} onHide={handleClose} className="widthdrawal-modal">
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="isResolved"
                value={isResolved}
                onChange={(e) => {
                  const { value } = e.target;
                  setIsResolved(value);
                  handleChangeData('isResolved', value);
                }}
              >
                <option value="0">Pending</option>
                <option value="1">Completed</option>
                <option value="2">Declined</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveData}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeTransactionDataModalButton;
