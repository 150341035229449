export const selectSmsServices = (state) => state.smsServices?.services ?? [];
export const selectSmsServiceById = (id) => (state) => state.smsServices?.services.find((service) => service._id === id) ?? null;
export const selectIsSmsServicesLoading = (state) => state.smsServices?.isServicesLoading ?? false;
export const selectDescriptionFilter = (state) => state.smsServices?.descriptionFilter ?? '';
export const selectServiceNameFilter = (state) => state.smsServices?.serviceNameFilter ?? '';
export const selectIsActiveFilter = (state) => state.smsServices?.isActiveFilter ?? '';
export const selectAgentFilter = (state) => state.smsServices?.agentFilter ?? '';
export const selectIntegratedServices = (state) => state.smsServices?.integratedServices ?? [];
export const selectAuthDataTypes = (state) => state.smsServices?.authDataTypes ?? [];
export const selectTotalCount = (state) => state.smsServices?.totalCount ?? 0;
export const selectCrmUserSmsServices = (state) => state.smsServices?.crmUserServices ?? [];
export const selectCrmUserSelectedSmsService = (state) => state.smsServices?.crmUserSelectedService ?? null;
