import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faBell, faGear, faCheck, faColumns, faVolumeMute, faVolumeHigh,
} from '@fortawesome/free-solid-svg-icons';
import { Flip, toast } from 'react-toastify';
import { 
  getNotificationsByModules, 
  readNotification,
  readAllNotification, 
  addNotification, 
  getNotificationModule,
  updateNotificationModuleState,
} from '../redux/notifications/notificationActions';
import { setHeaderMounted, setSideBarMounted } from '../redux/staticComponent/staticComponentActions';
import { getGlobalUsers } from '../redux/users/userActions';
import { getCRMUserInfo } from '../redux/crmUser/crmUserActions';
import { logout } from '../services/navigatorService';
import socket from '../services/socket';
import NotificationSound from '../assets/audio/notification.wav';
import lightLogo from '../assets/images/logo/darkLogoLong2.svg';
import { selectSmtpSelectedUserPreset, selectSmtpUserPresets } from '../redux/smtpPresets/smtpPresetsSelectors';
import { getCRMOptions, goToCrm } from '../redux/crm/crmActions';
import {
  fetchSmtpPresetsByUserId, setSelectedUserPreset, 
} from '../redux/smtpPresets/smtpPresetsActions';
import { NULLIFY_CRM_USER_INFO } from '../redux/crmUser/crmUserTypes';
import { activateVoipById, getUserVoips } from '../redux/voip/voipActions';
import { fetchSmsServiceByAgentId, setCrmUserSelectedSmsService } from '../redux/smsServices/smsServicesActions';
import { selectCrmUserSelectedSmsService, selectCrmUserSmsServices, selectSmsServices } from '../redux/smsServices/smsServicesSelectors';
import { UpdateCrmNotificationMessage, SelectComponent } from '../components';
import packageJson from '../../package.json';

const NOTIFICATION_FILTERS_STORAGE = 'notifications_filters';
const NOTIFICATION_SOUND_STORAGE = 'notifications_sound';
const audio = new Audio(NotificationSound);
audio.volume = 1;

const salesRoles = {
  sales: 1,
  retention: 2,
  both: 3,
};

const globalVersion = packageJson.version;

function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const userData = useSelector((state) => state?.crmUser?.crmUserInfo);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedCRM, setSelectedCRM] = useState();
  const [notification, setNotification] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [sound, setSound] = useState(() => JSON.parse(localStorage.getItem(NOTIFICATION_SOUND_STORAGE)) || 'on');
  const [notificationFilters, setNotificationFilters] = useState([]);

  const searchedUsers = useSelector((state) => state?.users?.globalUsers);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const unreadNotifications = useSelector((state) => state.notification.unreadNotifications);
  const notificationModules = useSelector((state) => state.notification.notificationModules);
  const isHeaderMounted = useSelector((state) => state.staticComponents.isHeaderMounted);
  const crmOptions = useSelector((state) => state.crmOptions);

  const userPresets = useSelector(selectSmtpUserPresets);
  const selectedUserPreset = useSelector(selectSmtpSelectedUserPreset);
  const crmUserSmsServices = useSelector(selectCrmUserSmsServices);
  const crmUserSelectedSmsService = useSelector(selectCrmUserSelectedSmsService);
  const smsServices = useSelector(selectSmsServices);
  const userVoips = useSelector((state) => state.voip.userVoips);
  const selectedVoip = useSelector((state) => state.voip.selectedVoip);
  const isSuperUser = userData?.role?.name === 'SuperAdmin';

  const isUserCanSeeClients = permissionName && permissionName.includes('clients');
  const notificationFiltersRef = useRef(notificationFilters);
  const soundRef = useRef(sound);

  const token = localStorage.getItem('token') ?? sessionStorage.getItem('token');
  const version = localStorage.getItem('version');

  const LANDING_URL = process.env.REACT_APP_LANDING_URL || null;

  useEffect(() => {
    if (isSuperUser) dispatch(getCRMOptions()); 
  }, [isSuperUser]);

  useEffect(() => {
    if (userData?._id) {
      dispatch(fetchSmtpPresetsByUserId(userData._id));
    }
  }, [userData?._id]);

  // getting new data if userData is loaded
  // or smsServices array have been changed
  useEffect(() => {
    if (userData?._id) {
      dispatch(fetchSmsServiceByAgentId(userData._id));
    }
  }, [userData?._id, smsServices]);

  // SMS  services effect
  useEffect(() => {
    // if all services removed
    if (!crmUserSmsServices.length) {
      dispatch(setCrmUserSelectedSmsService(null));
    }
    // if no one service selected and appeared services in selector
    if (crmUserSelectedSmsService === null && crmUserSmsServices.length) {
      dispatch(setCrmUserSelectedSmsService(crmUserSmsServices[0]));
    }
    // if selected service is not available anymore
    if (crmUserSelectedSmsService !== null && crmUserSmsServices.length) {
      const selectedServiceId = crmUserSelectedSmsService._id;
      const isServiceAvailable = crmUserSmsServices.some(
        (service) => service._id === selectedServiceId,
      );

      if (!isServiceAvailable) {
        dispatch(setCrmUserSelectedSmsService(crmUserSmsServices[0]));
      }
    }
  }, [crmUserSmsServices]);

  const handleSmtpSelectorChange = ({ target }) => {
    const smtpPresetId = target.value;
    const smtpPreset = userPresets.find((preset) => preset._id === smtpPresetId);
    dispatch(setSelectedUserPreset(smtpPreset));
  };

  const handleSmsSelectorChange = ({ target }) => {
    const smsServiceId = target.value;
    const smsService = crmUserSmsServices.find((service) => service._id === smsServiceId);
    dispatch(setCrmUserSelectedSmsService(smsService));
  };

  const handleCRMSelectorChange = (value) => {
    setSelectedCRM(value);

    if (value) goToCrm(value);
  };

  useEffect(() => {
    localStorage.getItem('user');
  }, [token]);

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem(NOTIFICATION_FILTERS_STORAGE));
    const newFilters = storedFilters || (notificationModules ? notificationModules.map((item) => item._id) : []);
    setNotificationFilters(newFilters);
    dispatch(updateNotificationModuleState());
  }, [notificationModules]);

  useEffect(() => {
    if (notificationFilters.length && userData._id) {
      const data = {
        userId: userData._id,
        modules: notificationFilters,
      };
      dispatch(getNotificationsByModules(data));
    }
  }, [notificationFilters, userData]);

  const handleModuleToggle = (moduleId) => {
    const updatedFilters = notificationFilters.includes(moduleId)
      ? notificationFilters.filter((id) => id !== moduleId)
      : [...notificationFilters, moduleId];

    setNotificationFilters(updatedFilters);
    localStorage.setItem(NOTIFICATION_FILTERS_STORAGE, JSON.stringify(updatedFilters));
  };

  const toggleSound = () => {
    const newSoundStatus = sound === 'on' ? 'off' : 'on';
    setSound(newSoundStatus);
    localStorage.setItem(NOTIFICATION_SOUND_STORAGE, JSON.stringify(newSoundStatus));
  };

  useEffect(() => {
    notificationFiltersRef.current = notificationFilters;
    soundRef.current = sound;
  }, [notificationFilters, sound]);

  const handleNotificationResponse = (data) => {
    const currentFilters = notificationFiltersRef.current;
    const currentSound = soundRef.current;
    
    if (currentFilters.includes(data.module)) {
      if (currentSound === 'on') {
        audio.play();
      }
      
      dispatch(addNotification(data));
    }
  };

  useEffect(() => {
    if (userData?._id) {
      socket.emit('startStreaming', { userId: userData._id, isCRMUser: true });
      socket.on(`onNotificationResponse${userData._id}`, handleNotificationResponse);
    }
    
    return () => {
      if (userData?._id) {
        socket.off(`onNotificationResponse${userData._id}`);
      } 
    };
  }, [userData]);

  useEffect(() => {
    if (unreadNotifications) {
      setNotification(unreadNotifications);
    }
  }, [unreadNotifications]);

  const redirectToPage = (url) => {
    history.push(url);
  };

  const markReadNotifcation = (id) => {
    dispatch(readNotification(id, userData._id));
  };

  const MakeAllNotificationsRead = async () => {
    const ids = notification.map((element) => element._id);
    dispatch(readAllNotification(ids, userData._id));
  };

  /* Global Search */
  const handleSearchValueChange = (e) => {
    const searchText = e.target.value;
    const uId = JSON.parse(localStorage.getItem('userId'));

    setSearchValue(searchText);
    dispatch(getGlobalUsers(uId, searchText));
  };

  useEffect(() => {
    if (!isHeaderMounted) {
      const loginUser = localStorage.getItem('userId');
      const uId = JSON.parse(loginUser);
      dispatch(setHeaderMounted(true));
      dispatch(getCRMUserInfo(uId));
      dispatch(getUserVoips(uId));
      dispatch(getNotificationModule());
    }
  }, [isHeaderMounted]);

  const logoutFromApp = () => {
    dispatch(setSideBarMounted(false));
    dispatch(setHeaderMounted(false));
    dispatch(setSelectedUserPreset(null));
    dispatch({ type: NULLIFY_CRM_USER_INFO });
    logout();
  };

  const handleActivateVoip = ({ target: { value } }) => {
    dispatch(activateVoipById(value));
  };
  
  const clearServiceWorkerCache = async () => {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      await Promise.all(
        registrations.map((registration) => registration.unregister()),
      );

      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName)),
      );

      if (window.indexedDB) {
        const databases = await window.indexedDB.databases();
        await Promise.all(
          databases.map((db) => window.indexedDB.deleteDatabase(db.name)),
        );
      }

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const user = localStorage.getItem('user');

      document.cookie.split(';').forEach((cookie) => {
        const name = cookie.split('=')[0].trim();
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      });

      localStorage.clear();
      sessionStorage.clear();

      localStorage.setItem('version', globalVersion);
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('user', user);
      
      window.location.reload(true);
    } catch (error) {
      console.error('Error clearing Service Worker cache:', error);
    }
  };

  useEffect(() => {
    const toastId = `update_${globalVersion}`;
    if (globalVersion !== version) {
      if (!toast.isActive(toastId)) {
        toast.dark(<UpdateCrmNotificationMessage version={globalVersion} />, {
          toastId,
          icon: '🚀',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'dark',
          transition: Flip,
          onClick: clearServiceWorkerCache,
        });
      }
    }
  }, [version, globalVersion]);

  return (
    <header id="header">
      <div className="container-fluid main-menu">
        <div className="row">
          <nav className="navbar navbar-expand-lg w-100 fixed-top main-padding header-navbar-row">
            <div>
              {
                LANDING_URL ? (
                  <a href={LANDING_URL} target="_blank" rel="noreferrer" className="logo">
                    <img className="img-fluid" src={lightLogo} alt="Logo" />
                  </a>
                ) : (
                  <Link to={isUserCanSeeClients ? ([salesRoles.sales, salesRoles.both].includes(userData.salesRole) ? '/leads' : userData.salesRole === salesRoles.retention ? '/users' : '/') : '/'} className="logo">
                    <img className="img-fluid" src={lightLogo} alt="Logo" />
                  </Link>
                )
              }
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon fa fa-bars" />
              </button>

              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <div
                  className="d-flex user-details-search"
                  style={{
                    display: 'flex', alignItems: 'center', gap: '10px', position: 'relative',
                  }}
                >
                  <form autoComplete="off">
                    <input
                      id="search"
                      type="search"
                      name="globalSearch"
                      className="form-control"
                      placeholder="Global search..."
                      autoComplete="off"
                      value={searchValue}
                      style={{ right: 0 }}
                      onChange={handleSearchValueChange}
                    />
                  </form>

                  {searchValue !== '' ? (
                    <div className="modal-search">
                      <h6>
                        Search Results:
                        {searchedUsers.length}
                      </h6>
                      <div className="modal-search-body">
                        {searchedUsers?.length && searchedUsers.map((searchedUser) => {
                          const userFullName = `${searchedUser?.firstName} ${searchedUser?.lastName}`;
                          const userEmail = searchedUser?.email;
                          const isClient = searchedUser?.roleId?.name === 'Client';
                          const userRoute = isClient ? `/user-detail/${searchedUser?._id}` : `/edit-admin/${searchedUser?._id}`;

                          return (
                            <Link
                              className="modal-search-link"
                              to={userRoute}
                              target="_blank"
                              key={`user-${searchedUser?._id}`}
                              onClick={() => setSearchValue('')}
                            >
                              {`${userFullName} (${userEmail})`}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    null
                  )}
                </div>
              </div>
            </div>
            <div>
              {
              userVoips.length && (
                <div id="navbarNavDropdown">
                  <div className="header-smtp-boxes-wrapper" id="navbarNavDropdown">
                    <span className="header-smtp-boxes-title">VOIP:</span>
                    <SelectComponent 
                      options={userVoips.map((voip) => ({ label: `${voip.provider.name} - ${voip.brand.name}`, value: voip._id }))}
                      value={selectedVoip?._id ?? null}
                      onChange={handleActivateVoip}
                    />
                  </div>
                </div>
              )
            }
              {
              userPresets.length && selectedUserPreset ? (
                <div id="navbarNavDropdown">
                  <div className="header-smtp-boxes-wrapper" id="navbarNavDropdown">
                    <span className="header-smtp-boxes-title">SMTP:</span>
                    <SelectComponent 
                      options={userPresets.map((preset) => ({ label: preset.description, value: preset._id }))}
                      value={selectedUserPreset?._id}
                      onChange={handleSmtpSelectorChange}
                    />
                  </div>
                </div>
              ) : null
            }
              {
              crmUserSmsServices.length ? (
                <div id="navbarNavDropdown">
                  <div className="header-smtp-boxes-wrapper" id="navbarNavDropdown">
                    <span className="header-smtp-boxes-title">SMS:</span>
                    {/* <select
                      defaultValue={crmUserSelectedSmsService?._id}
                      onChange={handleSmsSelectorChange}
                      className="form-control header-smtp-boxes-selector"
                    >
                      {crmUserSmsServices.map((service) => (
                        <option key={service._id} value={service._id}>
                          {service.description}
                        </option>
                      ))}
                    </select> */}
                    <SelectComponent 
                      options={crmUserSmsServices.map((service) => ({ label: service.description, value: service._id }))}
                      value={crmUserSelectedSmsService?._id}
                      onChange={handleSmsSelectorChange}
                    />
                  </div>
                </div>
              ) : null
            }
              {
              !!(isSuperUser && crmOptions.length) && (
                <div className="header-smtp-boxes-wrapper" id="navbarNavDropdown">
                  <span className="header-smtp-boxes-title">Go to:</span>
                  <SelectComponent 
                    options={crmOptions.map(({ label, value }) => ({ label, value }))}
                    value={selectedCRM}
                    onChange={({ target: { value } }) => handleCRMSelectorChange(value)}
                  />
                </div>
              )
            }
            </div>
            <div className="custom-items">
              {token ? (
                <>
                  {userData.firstName ? <span style={{ color: '#fff', marginRight: '30px' }}>{`${userData.firstName} ${userData.lastName}`}</span> : null}
                  <Link className="user-dropdown noti-btn-des" to="/additional-security">
                    <FontAwesomeIcon icon={faGear} />
                  </Link>

                  <Button className="noti-button noti-btn-des" onClick={handleShow}>
                    <FontAwesomeIcon icon={faBell} />
                    <div className="notifiction-badge">
                      {notification?.length ? notification?.length : '0'}
                    </div>
                  </Button>

                  <Modal className="noti-modal" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Notifications</Modal.Title>
                      <div className="action__btn-row ms-3">
                        <div className="main_btn-row">
                          <div className="secondary_btn-row">
                            <Dropdown className="leads-columns-dropdown">
                              <Dropdown.Toggle 
                                variant="" 
                                className="btn-secondary_dark"
                              >
                                Type
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="primary-menu">
                                <ul className="leads-columns-list">
                                  {notificationModules?.map((currmodule) => (
                                    <li className="leads-columns-option" key={currmodule._id} onClick={() => handleModuleToggle(currmodule._id)}>
                                      {notificationFilters.includes(currmodule._id) && (
                                      <FontAwesomeIcon
                                        size="xs"
                                        icon={faCheck}
                                        color="#6E7F95"
                                      />
                                      )}
                                      <span className="leads-columns-option__name">{currmodule.name}</span>
                                    </li>
                                  ))}
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                            <button
                              type="button"
                              className="btn-secondary_dark iconed"
                              onClick={toggleSound}
                              title={`Sound is ${sound.toUpperCase()}, click to turn ${sound === 'on' ? 'off' : 'on'}`}

                            >
                              <FontAwesomeIcon icon={sound === 'on' ? faVolumeHigh : faVolumeMute} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      {notification.length ? (
                        notification.map((i) => (
                          <span
                            onClick={() => {
                              markReadNotifcation(i._id);
                              redirectToPage(`${i.redirectUrl}`);
                            }}
                            className="content active"
                            key={i._id}
                          >
                            <span className="unread">Unread</span>
                            <h6 className="status">
                              {i.message}
                            </h6>
                            <p>
                              <span className="date">{moment(i.createdAt).format('LLL')}</span>
                              {/* <span className='time'>10:00 PM</span> */}
                            </p>
                          </span>
                        ))
                      ) : (
                        <div className="no-noti-found">
                          <span className="text-white">No notification found!</span>
                        </div>
                      )}
                    </Modal.Body>
                    {notification.length ? (
                      <Modal.Footer style={{ border: '0' }}>
                        <button
                          type="button"
                          className="btn-default"
                          style={{ backgroundColor: '#6E7F95' }}
                          onClick={MakeAllNotificationsRead}
                        >
                          Read All
                        </button>
                      </Modal.Footer>
                    ) : (
                      ''
                    )}
                  </Modal>

                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="noti-button m-0"
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Link className="dropdown-item conntect-wallet-btn">Connect Wallet</Link> */}
                      <Link className="dropdown-item" to="/user-profile">
                        Profile
                      </Link>
                      <Link className="dropdown-item" to="/change-password">
                        Change Password
                      </Link>
                      <a type="button" className="dropdown-item" onClick={logoutFromApp}>
                        Log Out
                      </a>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
