export const smsServicesColumns = [
  {
    name: 'Description',
    field: 'description',
    selected: true,
  },
  {
    name: 'Agent',
    field: 'user.assigned_to',
    selected: true,
  },
  {
    name: 'Service Name',
    field: 'service_name',
    selected: true,
  },
  {
    name: 'Status',
    field: 'is_active',
    selected: true,
  },
  {
    name: 'Auth Data',
    field: 'auth_data',
    selected: true,
  },
  {
    name: 'Additional Params',
    field: 'additional_params',
    selected: true,
  },
];

