import React, {
  useEffect,
  useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { createSmsService } from '../../../redux/smsServices/smsServicesActions';
import { selectIntegratedServices } from '../../../redux/smsServices/smsServicesSelectors';
import styles from './SmsServiceCreate.module.css';
import AuthDataComponent from '../AuthDataComponent/AuthDataComponent';
import AdditionalParamsComponent from '../AdditionalParamsComponent/AdditionalParamsComponent';
import { getCRMUsers } from '../../../redux/crmUser/crmUserActions';

function SmsServiceCreate({ hideModal }) {
  const dispatch = useDispatch();

  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const integratedServices = useSelector(selectIntegratedServices);

  const [description, setDescription] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [agent, setAgent] = useState(crmUsers[0]?._id);
  const [isActive, setIsActive] = useState(false);
  const [authData, setAuthData] = useState({});
  const [additionalParams, setAdditionalParams] = useState({});
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (crmUsers && crmUsers.length) {
      setAgent(crmUsers[0]._id);
    }
  }, [crmUsers]);

  useEffect(() => {
    if (integratedServices && integratedServices.length > 0) {
      setServiceName(integratedServices[0]);
    }
  }, [integratedServices]);

  const { handleSubmit } = useForm();

  const handleAuthDataChange = (newAuthData) => {
    setAuthData(newAuthData);
  };

  const handleAdditionalParamsChange = (newData) => {
    setAdditionalParams(newData);
  };

  const handleSave = async () => {
    setIsSending(true);

    const data = {
      description,
      service_name: serviceName,
      auth_data: authData,
      additional_params: additionalParams,
      is_active: isActive,
      assigned_to: agent,
    };
    dispatch(createSmsService(data, hideModal));
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="col-xl-12 col-lg-12">
        <form className="bitrex-form form-group" onSubmit={handleSubmit(handleSave)}>

          <div className="form-group">
            <label className="lead" htmlFor="label">Description</label>
            <input
              type="text"
              name="description"
              value={description}
              onInput={(e) => setDescription(e.target.value)}
              placeholder="Enter here..."
              className="form-control"
            />
          </div>
          <br />

          <div className="form-group">
            <label className="lead" htmlFor="label">Service Name</label>
            <select
              name="serviceName"
              value={serviceName}
              className={styles.selector}
              onChange={({ target: { value } }) => dispatch(setServiceName(value))}
            >
              {integratedServices.map((service) => <option key={service}>{service}</option>)}
            </select>
          </div>
          <br />

          <div className="form-group">
            <label className="lead">Status</label>
            <select
              className="form-control user-status-select"
              name="isActive"
              value={isActive}
              onChange={() => setIsActive(!isActive)}
            >
              <option value>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <br />

          <div className="form-group">
            <label className="lead">Agent</label>
            <select
              className="form-control user-status-select"
              name="agent"
              onChange={({ target }) => { setAgent(target.value); }}
            >
              {crmUsers.map((user, key) => (
                <option key={user._id} selected={key === 0} value={user._id}>
                  {`${user.firstName} ${user.lastName}`}
                </option>
              ))}
            </select>
          </div>
          <br />

          
          <AuthDataComponent onAuthDataChange={handleAuthDataChange} />
          <AdditionalParamsComponent onAdditionalParamsChange={handleAdditionalParamsChange} />

          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn-default"
              disabled={isSending}
            >
              Create Sms Service
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SmsServiceCreate;
