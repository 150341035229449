import React, { useState, useRef } from 'react';
import { FilterTableIcon } from '../assets/FilterTableIcon';
import styles from '../AffiliateDashboardFilter/AffiliateDashboardFilter.module.css';
import filterStyles from './AffiliatesFilter.module.css';
import { prettyCutOutputWidth } from '../utils/helpers';
import { useClickOutside } from '../../../hooks/useClickOutside';

export function AffiliatesFilter({
  data, selectedItems, setSelectedItems, handleChange, setFilterObject,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownDirection, setDropdownDirection] = useState('downwards');
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside({
    onClickOutside: () => setIsOpen(false),
    ref: dropdownRef,
  });

  const handleSelectAll = () => {
    const newSelectedItems = new Set(data.map((item) => item.value));
    setSelectedItems(newSelectedItems);
    setFilterObject((prevState) => ({ ...prevState, affiliate: [...newSelectedItems] }));
  };

  const handleDeselectAll = () => {
    setSelectedItems(new Set([]));
    setFilterObject((prevState) => ({ ...prevState, affiliate: [] }));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      const button = buttonRef.current;
      const rect = button.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 280;
      if (spaceBelow < dropdownHeight && rect.top > dropdownHeight) {
        setDropdownDirection('upwards');
      } else {
        setDropdownDirection('downwards');
      }
    }
  };

  const filteredData = data?.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];

  return (
    <div className={filterStyles.searchableCheckBoxListWrapper}>
      <button 
        ref={buttonRef} 
        type="button" 
        onClick={toggleDropdown} 
        className={
          selectedItems.size === 0
            ? filterStyles.searchableCheckBoxListWrapperBtn
            : filterStyles.selectedSearchableCheckBoxListWrapperBtn
        }
      >
        <FilterTableIcon status={selectedItems.size === 0 ? 'idle' : 'filled'} />
      </button>
      {isOpen && (
        <div ref={dropdownRef} className={`${filterStyles.searchableCheckBoxListDropdown} ${dropdownDirection === 'upwards' ? styles.upwards : ''}`}>
          <input
            type="text"
            className={styles.searchableCheckBoxListInput}
            placeholder="Affiliates search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className={styles.searchableCheckBoxListBtnsContainer}>
            <button type="button" onClick={handleSelectAll} className={styles.searchableCheckBoxListBtns}>Select All</button>
            <button type="button" onClick={handleDeselectAll} className={styles.searchableCheckBoxListBtns}>Deselect All</button>
          </div>
          <hr className={styles.searchableCheckBoxListHr} />
          <ul className={styles.searchableCheckBoxListUl}>
            {filteredData.length ? filteredData.map((item) => (
              <li key={item.value} className={styles.searchableCheckBoxListLI}>
                <label className={styles.searchableCheckBoxListLabel}>
                  {prettyCutOutputWidth(item.label, 20)}
                  <input
                    type="checkbox"
                    className={styles.searchableCheckBoxListCheckbox}
                    checked={selectedItems.has(item.value)}
                    onChange={() => handleChange(item.value)}
                  />
                </label>
              </li>
            )) : (
              <li className={styles.searchableCheckBoxListLI}>
                <label className={styles.searchableCheckBoxListLabel}>
                  No data
                </label>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
